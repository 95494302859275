<template>
    <auth-layout>
        <div>
            <div class="min-vh-100">
                <div class="row w-100 m-0">
                    <div
                        class="col-md-4 d-flex flex-column justify-content-between bg-white min-vh-100 overflow-hidden"
                        style="padding: 0 15px"
                    >
                        <CForm class="login-form" @submit.prevent="submit">
                            <h1 class="m-0">{{ __('auth.login.header') }}</h1>
                            <p class="text-muted">
                                {{ __('auth.login.description') }}
                            </p>
                            <CInput
                                id="email"
                                v-model="form.email"
                                :isValid="!hasErrors ? null : false"
                                :placeholder="__('auth.general.email')"
                                autocomplete="username email"
                                autofocus
                                class="bg-prepend-primary"
                                prependHtml="<i class='cui-user'></i>"
                                required
                            >
                                <template #prepend-content>
                                    <CIcon name="cil-user" />
                                </template>
                            </CInput>
                            <CInput
                                id="password"
                                v-model="form.password"
                                :isValid="!hasErrors ? null : false"
                                :placeholder="__('auth.general.password')"
                                autocomplete="curent-password"
                                class="bg-prepend-primary"
                                prependHtml="<i class='cui-lock-locked'></i>"
                                required
                                type="password"
                            >
                                <template #prepend-content>
                                    <CIcon name="cil-lock-locked" />
                                </template>
                            </CInput>
                            <label
                                class="d-flex justify-content-start"
                                style="gap: 4px"
                            >
                                <jet-checkbox
                                    v-model:checked="form.remember"
                                    name="remember"
                                />
                                <span class="text-sm text-gray-600">{{
                                    __('auth.login.remember')
                                }}</span>
                            </label>
                            <CRow class="mb-4">
                                <CCol>
                                    <jet-validation-errors />
                                </CCol>
                            </CRow>
                            <div class="d-flex justify-content-center">
                                <jet-button
                                    :is-processing="form.processing"
                                    class="px-4 mx-2"
                                    color="primary"
                                    type="submit"
                                    >{{ __('auth.login.action.login') }}
                                </jet-button>
                            </div>
                            <div class="d-flex justify-content-center mt-3">
                                <Link
                                    v-if="canResetPassword"
                                    :href="route('password.request')"
                                    >{{ __('auth.login.forgot_password') }}
                                </Link>
                            </div>
                        </CForm>
                        <div
                            class="d-flex flex-column align-items-start pt-3"
                            style="padding: 10px 10%"
                        >
                            <optional-link :href="curieTechUrl">
                                <CIcon
                                    class="curie-logo"
                                    name="logoCurie"
                                    style="width: auto; height: 22px"
                                    title="Curie Technologies"
                                />
                            </optional-link>
                            <div v-if="version" class="pt-2 pb-3 version">
                                <small :title="commit" class="text-muted"
                                    ><b>v{{ version }}</b></small
                                >
                            </div>
                        </div>
                        <div class="slide-container slide-container-left">
                            <div class="mask-left">
                                <ul
                                    :class="{ running: isAnimationRunning }"
                                    class="list-unstyled slide-track"
                                >
                                    <li class="slide">
                                        <img
                                            :src="cdnAsset('images/bag.jpg')"
                                        />
                                    </li>
                                    <li class="slide">
                                        <img
                                            :src="
                                                cdnAsset('images/suitcase.jpg')
                                            "
                                        />
                                    </li>
                                    <li class="slide">
                                        <img
                                            :src="
                                                cdnAsset(
                                                    'images/backpack_front.jpg'
                                                )
                                            "
                                        />
                                    </li>
                                    <li class="slide">
                                        <img
                                            :src="
                                                cdnAsset('images/trolley.jpg')
                                            "
                                        />
                                    </li>
                                    <li class="slide">
                                        <img
                                            :src="
                                                cdnAsset(
                                                    'images/backpack_side.jpg'
                                                )
                                            "
                                        />
                                    </li>
                                    <li class="slide">
                                        <img
                                            :src="cdnAsset('images/bag.jpg')"
                                        />
                                    </li>
                                    <li class="slide">
                                        <img
                                            :src="
                                                cdnAsset('images/suitcase.jpg')
                                            "
                                        />
                                    </li>
                                    <li class="slide">
                                        <img
                                            :src="
                                                cdnAsset(
                                                    'images/backpack_front.jpg'
                                                )
                                            "
                                        />
                                    </li>
                                    <li class="slide">
                                        <img
                                            :src="
                                                cdnAsset('images/trolley.jpg')
                                            "
                                        />
                                    </li>
                                    <li class="slide">
                                        <img
                                            :src="
                                                cdnAsset(
                                                    'images/backpack_side.jpg'
                                                )
                                            "
                                        />
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div
                        class="col-md-8 d-none d-sm-block overflow-hidden"
                        style="padding: 0 15px"
                    >
                        <div
                            class="min-vh-100 d-flex flex-column align-items-center"
                        >
                            <CIcon
                                class="fill-primary"
                                name="logoLarge"
                                style="
                                    position: relative;
                                    z-index: 2;
                                    width: auto;
                                    height: 80px;
                                    margin-top: 180px;
                                "
                            />

                            <div class="slide-container">
                                <div class="mask-right">
                                    <ul
                                        :class="{ running: isAnimationRunning }"
                                        class="list-unstyled slide-track"
                                    >
                                        <li class="slide">
                                            <img
                                                :src="
                                                    cdnAsset('images/bag_x.jpg')
                                                "
                                            />
                                        </li>
                                        <li class="slide">
                                            <img
                                                :src="
                                                    cdnAsset(
                                                        'images/suitcase_x.jpg'
                                                    )
                                                "
                                            />
                                        </li>
                                        <li class="slide">
                                            <img
                                                :src="
                                                    cdnAsset(
                                                        'images/backpack_front_x.jpg'
                                                    )
                                                "
                                            />
                                        </li>
                                        <li class="slide">
                                            <img
                                                :src="
                                                    cdnAsset(
                                                        'images/trolley_x.jpg'
                                                    )
                                                "
                                            />
                                        </li>
                                        <li class="slide">
                                            <img
                                                :src="
                                                    cdnAsset(
                                                        'images/backpack_side_x.jpg'
                                                    )
                                                "
                                            />
                                        </li>
                                        <li class="slide">
                                            <img
                                                :src="
                                                    cdnAsset('images/bag_x.jpg')
                                                "
                                            />
                                        </li>
                                        <li class="slide">
                                            <img
                                                :src="
                                                    cdnAsset(
                                                        'images/suitcase_x.jpg'
                                                    )
                                                "
                                            />
                                        </li>
                                        <li class="slide">
                                            <img
                                                :src="
                                                    cdnAsset(
                                                        'images/backpack_front_x.jpg'
                                                    )
                                                "
                                            />
                                        </li>
                                        <li class="slide">
                                            <img
                                                :src="
                                                    cdnAsset(
                                                        'images/trolley_x.jpg'
                                                    )
                                                "
                                            />
                                        </li>
                                        <li class="slide">
                                            <img
                                                :src="
                                                    cdnAsset(
                                                        'images/backpack_side_x.jpg'
                                                    )
                                                "
                                            />
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </auth-layout>
</template>

<script>
import JetButton from '@/Jetstream/Button.vue';
import JetCheckbox from '@/Jetstream/Checkbox.vue';
import JetValidationErrors from '@/Jetstream/ValidationErrors.vue';
import AuthLayout from '@/Layouts/AuthLayout.vue';
import OptionalLink from '@/Components/OptionalLink.vue';

export default {
    components: {
        JetButton,
        JetCheckbox,
        JetValidationErrors,
        AuthLayout,
        OptionalLink,
    },

    props: {
        canResetPassword: Boolean,
        status: String,
    },

    data() {
        return {
            form: this.$inertia.form({
                email: '',
                password: '',
                remember: false,
            }),
            isAnimationRunning: false,
        };
    },

    computed: {
        errors() {
            return this.$page.props.errors;
        },
        hasErrors() {
            return Object.keys(this.errors).length > 0;
        },
        version() {
            return this.$page.props.version?.semver;
        },
        commit() {
            return this.$page.props.version?.commit_hash;
        },
        curieTechUrl() {
            return this.$page.props.curie_tech_url;
        },
    },

    methods: {
        submit() {
            this.form
                .transform((data) => ({
                    ...data,
                    remember: this.form.remember ? 'on' : '',
                }))
                .post(this.route('login'), {
                    onFinish: () => this.form.reset('password'),
                });
        },
    },
};
</script>
<style lang="scss">
.login-form {
    position: relative;
    margin-top: 200px;
    padding: 0 10%;
    z-index: 2;
}

.curie-logo {
    position: relative;
    z-index: 2;
}

.fill-primary {
    path[fill],
    polygon[fill] {
        fill: #3e56c6 !important;
    }
}

.version {
    position: relative;
    z-index: 2;
}

.mask-left {
    @media (min-width: 576px) {
        clip-path: inset(0 33.3333vw 0 0);
    }
}

.mask-right {
    clip-path: inset(0 0 0 33.3333vw);
}

.slide-container {
    position: fixed;
    z-index: 1;
    left: 0;
    right: 0;
    bottom: 100px;
    height: 10vw;

    img {
        max-height: 10vw;
    }

    @media (max-width: 767px) {
        display: none;
    }
}

.slide-container-left {
    @media (max-height: 700px) {
        opacity: 0;
    }
}

$slides: 5;
$slide_width: 250px;
$slide_margin: 200px;

.slide-track {
    animation: slide 50s linear infinite;
    display: flex;
    width: calc(($slide_width + 2 * $slide_margin) * $slides * 2);
}

.slide {
    width: $slide_width;
    margin: 0 $slide_margin;
    text-align: center;
}

@keyframes slide {
    0% {
        transform: translateX(
            calc(($slide_width + 2 * $slide_margin) * $slides * -1)
        );
    }
    100% {
        transform: translateX(0);
    }
}
</style>
