<template>
    <app-layout :enable-flash-message="false" class="no-main-padding">
        <div class="sticky-header">
            <FlashMessage />
            <div
                class="d-md-flex justify-content-start align-items-center"
                style="max-width: 100%"
            >
                <h2 class="h4 m-0 flex-shrink-0">
                    {{ __('workorder.overview.title') }}
                </h2>
                <div>
                    <jet-button
                        v-if="hasAnyPermission('workorder.create')"
                        :disabled="isOperationInProgress"
                        class="text-nowrap sm-full-width mx-md-4 my-2 my-md-0"
                        type="button"
                        @click="openCreateWorkOrderModal"
                    >
                        {{ __('workorder.overview.action.create') }}
                    </jet-button>
                </div>
            </div>

            <work-order-filter-bar
                :is-processing="isLoadingWorkOrders"
                class="mt-2"
                @change="updateFilter"
                @clear="clearFilters"
            />
        </div>

        <div>
            <h3 class="py-2 h5">
                {{ __('workorder.overview.board.title') }}
            </h3>
            <work-order-board
                :key="`wb-${componentKey}`"
                :highlighted-work-order-id="highlightedWorkOrderId"
                :selected-work-order="selectedWorkOrder"
                :swimlanes="swimlanes"
                class="pb-3"
                @close="selectedWorkOrder = null"
                @selected="selectedWorkOrder = $event"
                @load-details="loadWorkOrderDetails"
                @status-transitioned="handleWorkOrderStatusTransition"
            />

            <h3 class="py-2 h5">
                {{ __('workorder.overview.records.title') }}
                <info-icon
                    :tooltip="__('assets.workorders.records.info')"
                    style="position: relative; top: -1px"
                />
            </h3>

            <work-order-records
                :key="`wr-${componentKey}`"
                :work-orders="archivedWorkOrders"
                @click="loadWorkOrderDetails"
            />

            <work-order-details-modal
                :editable="isSelectedWorkOrderEditable"
                :show="activeModal === 'WorkOrderDetailsModal'"
                :work-order-id="selectedWorkOrder?.id"
                @close="closeModal"
                @status-transitioned="handleWorkOrderStatusTransition"
            />

            <create-work-order-modal
                v-if="hasAnyPermission('workorder.create')"
                :show="showCreateWorkOrderModal"
                @close="onCreateWorkOrderModalClose"
            />
            <asset-status-change-prompt-modal
                :asset-id="assetStatusChange.assetId"
                :operability="assetStatusChange.operability"
                :reason="assetStatusChange.reason"
                :show="activeModal === 'AssetStatusChangePromptModal'"
                @cancel="closeModal"
                @close="closeModal"
            />
        </div>
    </app-layout>
</template>
<script>
import AppLayout from '@/Layouts/AppLayout.vue';
import AssetDetailsHeader from '@/Pages/Assets/Details/AssetDetailsHeader.vue';
import WorkOrderDetailsModal from '@/Pages/WorkOrders/Modals/WorkOrderDetailsModal.vue';
import ConfirmWorkOrderDeletionModal from '@/Pages/WorkOrders/Modals/ConfirmWorkOrderDeletionModal.vue';
import WorkOrderRecords from '@/Pages/WorkOrders/WorkOrderRecords.vue';
import WorkOrderBoard from '@/Pages/WorkOrders/WorkOrderBoard.vue';
import CreateWorkOrderModal from '@/Pages/WorkOrders/Modals/CreateWorkOrderModal.vue';
import JetButton from '@/Jetstream/Button.vue';
import TooltipWrapper from '@/Pages/Assets/Details/TooltipWrapper.vue';
import WorkOrderFilterBar from '@/Pages/WorkOrders/WorkOrderFilterBar.vue';
import FilterBar from '@/Components/FilterBar.vue';
import { removeEmptyProperties } from '@/utils';
import AssetSelectionField from '@/Pages/WorkOrders/WorkOrderForm/AssetSelectionField.vue';
import InfoIcon from '@/Components/InfoIcon.vue';
import ConfirmAssetStatusChangeModal from '@/Pages/Assets/Details/ConfirmAssetStatusChangeModal.vue';
import AssetStatusChangePromptModal from '@/Pages/WorkOrders/Modals/AssetStatusChangePromptModal.vue';
import FlashMessage from '@/Components/FlashMessage.vue';

export default {
    components: {
        FlashMessage,
        AssetStatusChangePromptModal,
        ConfirmAssetStatusChangeModal,
        InfoIcon,
        AssetSelectionField,
        FilterBar,
        WorkOrderFilterBar,
        TooltipWrapper,
        JetButton,
        CreateWorkOrderModal,
        WorkOrderBoard,
        WorkOrderRecords,
        ConfirmWorkOrderDeletionModal,
        WorkOrderDetailsModal,
        AssetDetailsHeader,
        AppLayout,
    },
    data() {
        return {
            componentKey: 0,
            activeModal: null,
            selectedWorkOrder: null,
            highlightedWorkOrderId: null,
            isOperationInProgress: false,
            showCreateWorkOrderModal: false,
            isLoadingWorkOrders: false,
            activeQueryParams: null,
            assetStatusChange: {
                assetId: null,
                operability: 'inoperable',
                reason: 'corrective_maintenance_required',
            },
        };
    },
    computed: {
        workOrders() {
            return this.$page.props.workOrders?.data || [];
        },
        archivedWorkOrders() {
            return this.$page.props.archivedWorkOrders;
        },
        swimlanes() {
            return this.$page.props.swimlanes || [];
        },
        statuses() {
            return this.swimlanes.map((lane) => lane.status.value);
        },
        isSelectedWorkOrderEditable() {
            return (
                (this.selectedWorkOrder?.isEditable &&
                    this.hasAnyPermission('workorder.update')) ||
                false
            );
        },
        workOrdersRouteUrl() {
            let queryParams = Object.assign(
                {},
                removeEmptyProperties(this.activeQueryParams)
            );
            return this.route('workorders.index', queryParams);
        },
    },
    watch: {
        selectedWorkOrder(workOrder) {
            const currentRoute = this.route().current();
            const urlToSet = !workOrder
                ? this.route(currentRoute)
                : this.route(currentRoute, { workorder: workOrder.id });

            window.history.replaceState({}, '', urlToSet);

            if (!!workOrder) {
                this.highlightedWorkOrderId = workOrder.id;
            }
        },
    },
    mounted() {
        this.loadWorkOrderDetails(this.$page.props.selectedWorkOrder);
    },
    methods: {
        refreshWorkOrders() {
            this.$inertia.get(
                this.workOrdersRouteUrl,
                {},
                {
                    only: [...this.statuses, 'archivedWorkOrders'],
                    preserveState: true,
                    onBefore: () => {
                        this.isLoadingWorkOrders = true;
                    },
                    onFinish: () => {
                        this.forceRerender();
                        this.isLoadingWorkOrders = false;
                    },
                }
            );
        },
        forceRerender() {
            this.componentKey += 1;
        },
        openCreateWorkOrderModal() {
            this.selectedWorkOrder = null;
            this.showCreateWorkOrderModal = true;
        },
        onCreateWorkOrderModalClose() {
            this.showCreateWorkOrderModal = false;
            this.forceRerender();
        },
        closeModal() {
            this.activeModal = null;

            setTimeout(() => {
                this.forceRerender();
                this.selectedWorkOrder = null;
            }, 150);
        },
        loadWorkOrderDetails(workOrder) {
            if (!!this.activeModal || !workOrder) {
                return;
            }

            this.selectedWorkOrder = workOrder;
            this.activeModal = 'WorkOrderDetailsModal';
        },
        updateFilter: function (filters) {
            this.activeQueryParams = filters;
            this.refreshWorkOrders();
        },
        clearFilters() {
            this.activeQueryParams = {};
            this.refreshWorkOrders();
        },
        handleWorkOrderStatusTransition({ workOrder, status }) {
            this.$http
                .get(this.route('workorders.show', workOrder.id))
                .then((response) => {
                    let asset = response.data.workOrder.asset;
                    let operability =
                        response.data.workOrder.asset.status.operability;

                    this.highlightedWorkOrderId = workOrder.id;
                    this.forceRerender();

                    if (status === 'to_do') {
                        return;
                    }

                    switch (`${status}:${operability}`) {
                        case 'in_progress:operable':
                        case 'resolved:inoperable':
                        case 'closed:inoperable':
                            this.assetStatusChange.operability =
                                operability === 'operable'
                                    ? {
                                          value: 'inoperable',
                                          label: this.__(
                                              'assets.modal.operability_change.label.inoperable'
                                          ),
                                      }
                                    : {
                                          value: 'operable',
                                          label: this.__(
                                              'assets.modal.operability_change.label.operable'
                                          ),
                                      };
                            this.assetStatusChange.assetId = asset.id;
                            this.assetStatusChange.reason = workOrder.type;
                            this.activeModal = 'AssetStatusChangePromptModal';
                            break;
                    }
                });
        },
    },
};
</script>
<style scoped>
@media (min-width: 576px) {
    .sticky-header {
        position: sticky;
        top: 64px;
        padding: 32px 30px 12px;
        z-index: 1020;
        background: #ebedef;
        margin: -32px -30px 0;
    }
}
</style>
