<template>
    <div>
        <dl>
            <div class="row">
                <dt class="col-3 font-weight-normal text-truncate">
                    {{ __('workorder.form.asset.asset.label') }}
                </dt>
                <dd :class="{ deleted: isDeleted }" class="col-9 text-muted">
                    <span v-if="isDeleted">{{ asset?.name }}</span>
                    <c-link v-else :href="url">{{ asset?.name }}</c-link>
                </dd>
            </div>
            <div class="row">
                <dt class="col-3 font-weight-normal text-truncate">
                    {{ __('workorder.form.asset.type.label') }}
                </dt>
                <dd :class="{ deleted: isDeleted }" class="col-9 text-muted">
                    <span>{{ type }}</span>
                </dd>
            </div>
            <div class="row">
                <dt class="col-3 font-weight-normal text-truncate">
                    {{ __('workorder.form.asset.status.label') }}
                </dt>
                <dd v-if="isDeleted" class="col-9 text-muted">
                    <span
                        class="text-danger d-flex align-items-center"
                        style="gap: 4px"
                    >
                        <exclamation-mark />
                        {{ __('workorder.form.asset.status.deleted') }}</span
                    >
                </dd>
                <dd v-else class="col-9 text-muted">
                    <div v-if="status">
                        <status-badge
                            :inline="true"
                            :operability="status.operability"
                            :usage="status.usageCategory"
                            class="text-muted"
                        />
                        <additional-status-information
                            :additional-information="
                                asset.status
                                    .operabilityChangeAdditionalInformation
                            "
                            :asset="asset"
                            :is-onboarded="!!asset.petitec_identifier"
                            :is-operable="
                                asset.status.operability === 'operable'
                            "
                            :reason="asset.status.operabilityChangeReason"
                        />
                    </div>
                    <div v-else>{{ __('general.not_available') }}</div>
                </dd>
            </div>
            <div class="row">
                <dt class="col-3 font-weight-normal text-truncate">
                    {{ __('workorder.form.asset.usage_category.label') }}
                </dt>
                <dd :class="{ deleted: isDeleted }" class="col-9 text-muted">
                    {{ usageCategory }}
                </dd>
            </div>
            <div class="row">
                <dt class="col-3 font-weight-normal text-truncate">
                    {{ __('workorder.form.asset.petitec_id.label') }}
                </dt>
                <dd :class="{ deleted: isDeleted }" class="col-9 text-muted">
                    {{ petitecId }}
                </dd>
            </div>
            <div class="row">
                <dt class="col-3 font-weight-normal text-truncate">
                    {{ __('workorder.form.asset.serial_number.label') }}
                </dt>
                <dd :class="{ deleted: isDeleted }" class="col-9 text-muted">
                    {{ serialNumber }}
                </dd>
            </div>
            <div class="row">
                <dt class="col-3 font-weight-normal text-truncate">
                    {{ __('workorder.form.asset.location.label') }}
                </dt>
                <dd :class="{ deleted: isDeleted }" class="col-9 text-muted">
                    <span>{{ location }}</span>
                </dd>
            </div>
            <div class="row">
                <dt class="col-3 font-weight-normal text-truncate">
                    {{ __('workorder.form.asset.manufacturer.label') }}
                </dt>
                <dd :class="{ deleted: isDeleted }" class="col-9 text-muted">
                    <span>{{ manufacturer }}</span>
                </dd>
            </div>
        </dl>
    </div>
</template>
<script>
import StatusBadge from '@/Components/StatusBadge.vue';
import ExclamationMark from '@/../icons/ExclamationMark.vue';
import AdditionalStatusInformation from '@/Components/AdditionalStatusInformation.vue';

export default {
    name: 'work-order-form-asset-details',
    components: { AdditionalStatusInformation, ExclamationMark, StatusBadge },
    props: ['asset'],
    computed: {
        url() {
            if (!this.asset) {
                return;
            }
            return this.route('assets.show', this.asset);
        },
        type() {
            return this.asset?.type?.label || this.__('general.not_available');
        },
        status() {
            return this.asset?.status;
        },
        petitecId() {
            return (
                this.asset?.petitec_identifier ||
                this.__('general.not_available')
            );
        },
        usageCategory() {
            return this.asset?.status.usageCategory
                ? this.__('assets.status.' + this.asset.status.usageCategory)
                : this.__('general.not_available');
        },
        serialNumber() {
            return (
                this.asset?.serial_number || this.__('general.not_available')
            );
        },
        location() {
            if (!this.asset?.location) {
                return this.__('general.not_available');
            }

            return [
                this.asset?.location.physical_location_name,
                this.asset?.location.name,
            ]
                .filter((el) => !!el)
                .join('/');
        },
        manufacturer() {
            return (
                this.asset?.manufacturer?.name ||
                this.__('general.not_available')
            );
        },
        isDeleted() {
            return !!this.asset?.deleted_at;
        },
    },
};
</script>
<style scoped>
.deleted {
    text-decoration: line-through;
}
</style>
